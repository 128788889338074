/* eslint-disable no-unused-vars */
import React from "react";

import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import { I18nextProvider } from "react-i18next";
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";

import WebSocketConnectionProvider from "../src/services/webSocket/ws";
import WebSocketBalanceConnectionProvider from "../src/services/wallet/wallet";
import AppRoutes from "./App.routes";
import getDarkTheme from "./theme/dark";
import getLightTheme from "./theme/light";
import i18n from "./i18n";
import WebNotificationSocketProvider from "./services/webSocket/notificationsSocket";
import LocationTracker from "./services/location/LocationTracker";

import "./App.css";

function App() {
  if (!localStorage.theme) localStorage.setItem("theme", "dark");

  const { darkMode, sidebarOpen, isChatBoxOpen } = useSelector(
    (state) => state.themeSettings
  );
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const { userDetails } = useSelector((state) => state.myProfile);
  let selectTheme = !darkMode ? getLightTheme() : getDarkTheme();
  const theme = createTheme(selectTheme);
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <WebSocketBalanceConnectionProvider>
            <WebSocketConnectionProvider>
              <WebNotificationSocketProvider>
                <I18nextProvider i18n={i18n}>
                  <CssBaseline />
                  <LocationTracker />
                  <AppRoutes
                    sidebarOpen={sidebarOpen}
                    isChatBoxOpen={isChatBoxOpen}
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails}
                  />
                  <Toaster
                    position="top-center"
                    toastOptions={{
                      success: {
                        duration: 2000,
                        style: {
                          background: darkMode ? "#24242B" : "#F3F1EA",
                          color: darkMode ? "#ffffff" : "#202228",
                        },
                      },
                      error: {
                        duration: 2000,
                        style: {
                          background: darkMode ? "#24242B" : "#F3F1EA",
                          color: darkMode ? "#ffffff" : "#202228",
                        },
                      },
                    }}
                  />
                </I18nextProvider>
              </WebNotificationSocketProvider>
            </WebSocketConnectionProvider>
          </WebSocketBalanceConnectionProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;
