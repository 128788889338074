import React, { useEffect, useMemo, useState } from "react";

import { Country,State } from "country-state-city";
import Select from "react-select";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import useStyles from "../../Header/KycVerification/KycVerification.styles";
import { billingAddressStart } from "../../../redux-saga/redux/auth/authentication";

import "./kyc.css";

const Step2 = ({ register,errors,setValue,handleSubmit,prevStep }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const options = useMemo(() => Country.getAllCountries().map((el)=>{return {value:el?.isoCode,label:el?.name}}), []);
  const [stateOptions, setStateOptions] = useState([]);
console.log("options",options)
  const changeCountryHandler = (value) => {
    setValue("country",value?.label)
    setSelectedCountry(value);
    const countyr=Country.getAllCountries().find((el)=>el?.isoCode === value?.value)
    setValue("countryPhoneCode",countyr?.phonecode)
    setSelectedState(null);
  };

  const changeStateHandler = (value) => {
    console.log("change state value",value)
    setValue("state",value?.label)
    setSelectedState(value);
  };
  useEffect(() => {
    if(selectedCountry?.value){
      setStateOptions(State.getStatesOfCountry(selectedCountry?.value).map((el)=>{return {label:el?.name,value:el?.name}}) || [])
    }
  }, [selectedCountry?.value]);
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      // background: "black",
    }),
    option: (provided) => ({
      ...provided,
      background: "#1D1D23",
      color: "#757786",
      fontWeight: "400",
      fontSize: "16px",
      padding: "10px 20px",
      lineHeight: "16px",
      cursor: "pointer",
      borderBottom: "1px solid #141414",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    input: (provided) => ({
      ...provided,
      color: "#757786",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 12px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "4px",
      color: "red",
      height: "32px",
    }),
    svg: (provided) => ({
      ...provided,
      fill: "#757786 !important",
      ":hover": {
        fill: "#757786 !important",
      },
    }),
  };


  const classes = useStyles();
   const dispatch = useDispatch();
    const onSubmit = (data) => {
      try {
        dispatch(billingAddressStart(data));
      } catch (err) {
        toast.error("Something went wrong!", { toastId: "123" });
      }
  };

  return (
    
    <>
          <div className="kycform">
      <div className="stepWrap">
        <span>Step 2/2</span>
      </div>
      <form className="kycFormWrap" onSubmit={handleSubmit(onSubmit)}>
        <div className="secondCopyInput1">
          <span className="title">Number address</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="number"
              className="custom-input"
              placeholder="Enter your address number"
              {...register("addressNumber")}
              //   value={webReceipt}
              //   onChange={(e) => setWebReceipt(e.target.value)}
            />
            {errors?.addressNumber ? (
              <span className={classes.errorMsgVal}>
                {errors.addressNumber.message}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="secondCopyInput1">
          <span className="title">Street Address 1</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="Enter your first street address"
              {...register("streetAddress1")}
              //   value={webReceipt}
              //   onChange={(e) => setWebReceipt(e.target.value)}
            />
            {errors?.streetAddress1 ? (
              <span className={classes.errorMsgVal}>
                {errors.streetAddress1.message}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="secondCopyInput1">
          <span className="title">Street Address 2</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="Enter your second street address"
              {...register("streetAddress2")}
              //   value={webReceipt}
              //   onChange={(e) => setWebReceipt(e.target.value)}
            />
            {errors?.streetAddress2 ? (
              <span className={classes.errorMsgVal}>
                {errors.streetAddress2.message}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="secondCopyInput0">
          {/* <div className="secondCopyInput2">
            <span className="title">City</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="text"
                className="custom-input"
                placeholder="City"
              />
            </div> */}
          {/* </div> */}
         

          <div className="secondCopyInput2">
            <span className="title">Country</span>
            <div className="copyWrap copyWrapSec">
              <Select
                classNamePrefix="react-select"
                styles={customStyles}
                className="custom-dropdown"
                options={options}
                value={selectedCountry}
                onChange={(e)=>changeCountryHandler(e)}
                placeholder="Country"
              />
              {errors?.country ? (
                <span className={classes.errorMsgVal}>
                  {errors.country.message}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="secondCopyInput2">
            <span className="title">State</span>
            <div className="copyWrap copyWrapSec">
              <Select
                classNamePrefix="react-select"
                styles={customStyles}
                className="custom-dropdown"
                options={stateOptions || []}
                value={selectedState}
                onChange={changeStateHandler}
                placeholder="State"
                
              />
              {errors?.state ? (
                <span className={classes.errorMsgVal}>
                  {errors.state.message}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="secondCopyInput0">
        <div className="secondCopyInput2">
            <span className="title">City</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="text"
                className="custom-input"
                placeholder="City"
                {...register("city")}
                //   value={webReceipt}
                //   onChange={(e) => setWebReceipt(e.target.value)}
              />
              {errors?.city ? (
                <span className={classes.errorMsgVal}>
                  {errors.city.message}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="secondCopyInput2">
            <span className="title">Zip Code</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="number"
                className="custom-input"
                placeholder="Zip Code"
                {...register("zipCode")}
                //   value={webReceipt}
                //   onChange={(e) => setWebReceipt(e.target.value)}
              />
              {errors?.zipCode ? (
                <span className={classes.errorMsgVal}>
                  {errors.zipCode.message}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      
                    <div className="flexBtnDiv">
                      <div
                        className="select-payment-method-button"
                        style={{ width: "100%" }}
                      >
                        <button
                          type="button"
                          style={{ textWrap: "nowrap" }}
                          onClick={prevStep}
                          //  disabled={loading} onClick={() => setStep(StepsEnum.Method)}
                        >
                          Back step
                        </button>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div className="buyNowBtnWrap">
                          <button
                            type="submit"
                            // disabled={disableButton}
                            // onClick={handlePaymentSuccessClick}
                            style={{ fontWeight: "500" }}
                          >
                            {/* <span style={{ fontWeight: "500px" }}>
              Pay</span>$
              {activeCard?.price} */}
                            {/* {loading ? ( */}
                            <div className="select-payment-method-button">
                              {/* <GlobalLoader /> */} Verify
                            </div>
                            {/* ) : (
                "Submit"
              )} */}
                          </button>
                        </div>
                      </div>
                    </div>
               

        </form>
</div>
    </>  
  
    
  );
};

export default Step2;
