import React from "react";

import useStyles from "../../Header/KycVerification/KycVerification.styles";

import "./kyc.css";

const Step1 = ({ register, errors, handleSubmit, nextStep }) => {
 
  const classes = useStyles();
  
  return (
    <>
      <div className="kycform">
        <div className="stepWrap">
          <span>Step 1/2</span>
        </div>
        <form onSubmit={handleSubmit(nextStep)} className="kycFormWrap" >
          <div className="secondCopyInput1 first-input  ">
            <span className="title">First Name</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="text"
                className="custom-input"
                placeholder="Enter your first name"
                {...register("firstName")}
              />
              {errors?.firstName ? (
                <span className={classes.errorMsgVal}>
                  {errors.firstName.message}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="secondCopyInput1">
            <span className="title">Last Name</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="text"
                className="custom-input"
                placeholder="Enter your last name"
                {...register("lastName")}

                //   value={webReceipt}
                //   onChange={(e) => setWebReceipt(e.target.value)}
              />
              {errors?.lastName ? (
                <span className={classes.errorMsgVal}>
                  {errors.lastName.message}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="secondCopyInput1">
            <span className="title">Date of birth</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="text"
                className="custom-input"
                placeholder="mm.dd.yy"
                {...register("dateOfBirth")}

                //   value={webReceipt}
                //   onChange={(e) => setWebReceipt(e.target.value)}
              />
              {errors?.dateOfBirth ? (
                <span className={classes.errorMsgVal}>
                  {errors.dateOfBirth.message}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="secondCopyInput1">
            <span className="title">Phone number</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="number"
                className="custom-input"
                placeholder="Enter your phone number"
                {...register("phoneNumber")}

                //   value={webReceipt}
                //   onChange={(e) => setWebReceipt(e.target.value)}
              />
              {errors?.phoneNumber ? (
                <span className={classes.errorMsgVal}>
                  {errors.phoneNumber.message}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="buyNowBtnWrap cardWrapScreen">
            <button type="submit">Next step</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step1;
