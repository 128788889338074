const config = {
  appEnv: process.env.REACT_APP_ENV,
  baseUrl: process.env.REACT_APP_SERVER_URL,
  clientUrl: process.env.REACT_APP_BASE_URL,
  xcoinPaymentUrl:
    process.env.REACT_APP_ENV === "production"
      ? "https://instantpay.xcoins.com"
      : "https://sandbox-onramp.xcoins.network",
  radarKey: "prj_live_pk_3e6a93d22a073585d2eaabf1214096f1e4eee5d0",
};

export default config;
