import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Radar from "radar-sdk-js";

import config from "../../config";

const LocationTracker = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      getLocation();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const getLocation = () => {

   Radar.initialize(config.radarKey);

    const skipVerifyApp = true;

    Radar.trackVerified({ skipVerifyApp })
      .then((result) => {
        const { passed, user } = result;
       if (user) {
         if (!passed) {
           navigate("not-found");
          }
       }
      })
      .catch((err) => {
        console.error("Error tracking location:", err);
     });
  };


  return null;
};

export default LocationTracker;
