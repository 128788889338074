import { makeStyles } from "@mui/styles";
// import { filter } from "lodash";

export default makeStyles((theme) => ({
  homeBanner: {
    backgroundImage: "url('/images/banner.webp')",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    minHeight: "350px",
    borderRadius: "20px",
    margin: "20px 0",
  },
  rightArrow: {
    background: "#2B2B38!important",
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "8px !important",
    height: "33px",
    width: "33px",
    minWidth: "33px !important",
    padding: "0 !important",
    [theme.breakpoints.down(1028)]: {
      height: "25px",
      width: "25px",
      minWidth: "25px !important",
    },
    "& svg": {
      fill: "#666773",
      [theme.breakpoints.down("md")]: {
        width: "15px",
      },
      [theme.breakpoints.down(1028)]: {
        width: "15px",
      },
    },
    [theme.breakpoints.down("md")]: {
      height: "26px",
      width: "26px",
      minWidth: "26px !important",
    },
    "&:hover": {
      backgroundColor: "rgba(43, 43, 56, 0.8)",
    },
  },
  leftArrow: {
    background: "#2B2B38!important",
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "8px !important",
    height: "33px",
    width: "33px",
    minWidth: "33px !important",
    padding: "0 !important",
    display: "flex !important",
    opacity: "1 !important",
    [theme.breakpoints.down(1028)]: {
      height: "25px",
      width: "25px",
      minWidth: "25px !important",
    },
    "& svg": {
      fill: "#666773",
      [theme.breakpoints.down("md")]: {
        width: "15px",
      },
      [theme.breakpoints.down(1028)]: {
        width: "15px",
      },
    },
    [theme.breakpoints.down("md")]: {
      height: "26px",
      width: "26px",
      minWidth: "26px !important",
    },
    "&:hover": {
      backgroundColor: "rgba(43, 43, 56, 0.8)",
    },
  },
  bannerContentSubText: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
      alignItems: "flex-start",
    },
  },
  btnWrap: {
    margin: "10px 0",
    display: "block",
  },
  btnPrimary: {
    border: "1.4px solid rgba(106, 113, 199, 0.84)",
    background:
      "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%) !important",
    borderRadius: "8px !important",
    padding: "6px 20px",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "600",
    textTransform: "capitalize",
    margin: "0 auto",
    width: "fit-content",
  },
  btnSecondary: {
    background: "#440073",
    borderRadius: "10px",
    padding: "6px 20px",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "600",
    textTransform: "capitalize",
    cursor: "pointer",
    "&:hover": {
      background: "#360359",
      color: "#fff",
    },
  },
  bannerContent: {
    padding: "30px 0 30px 60px",
    "& .MuiTypography-h1": {
      fontWeight: "bold",
      fontSize: "52px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "32px",
      },
      "& br": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    "& img": {
      marginRight: "5px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0",
        width: "100px",
      },
    },
    "& .MuiTypography-body1": {
      fontSize: "32px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    "& .MuiButton-textPrimary": {
      padding: "6px 40px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 15px",
    },
  },
  resetSearch: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "15px",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("sm")]: {
      order: "1",
    },
  },

  homeFilter: {
    padding: "12px 0 38px 0",
    maxWidth: "390px",
    width: "100%",
    [theme.breakpoints.down(1500)]: {
      padding: "10px 0",
    },
    "& .MuiInputBase-colorPrimary": {
      width: "100%",
      background: theme.palette?.background?.footer,
      border: theme.palette?.background?.inputBorder,
      boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "12px",
      color: "#000",
      [theme.breakpoints.down("md")]: {
        marginRight: "10px",
        width: "100%",
        fontSize: "14px",
      },
      [theme.breakpoints.down("sm")]: {
        borderRadius: "8px",
      },
      [theme.breakpoints.down(1500)]: {
        marginRight: "10px",
        width: "100%",
        fontSize: "14px",
      },
    },
    "& .MuiInputBase-input": {
      height: "34px",
      padding: "5px 10px 5px 20px",
      background: theme.palette?.background?.footer,
      color: `${theme?.palette?.text?.default} !important`,
      borderRadius: "12px",
      fontSize: "14px",
      "&::placeholder": {
        color: "#4D4D5B",
        fontWeight: "500",
        opacity: "1",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          color: "#757786",
        },
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        height: "30px",
        borderRadius: "8px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#000",
    },
    "& img": {
      filter: "invert(1)",
      opacity: "0.3",
    },
    [theme.breakpoints.down(675)]: {
      maxWidth: "100%",
    },
  },

  innerHeading: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: "0px",
    gap: "20px",

    [theme.breakpoints.down(1500)]: {
      flexDirection: "column",
      gap: "10px",
    },

    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
    },

    [theme.breakpoints.down(675)]: {
      flexDirection: "column",
      gap: "0",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  innerHeadingLeft: {
    display: "flex",
    alignItems: "center",
    maxWidth: "486px",
    width: "100%",
    paddingTop: "12px",
    justifyContent: "space-between",
    [theme.breakpoints.down(1500)]: {
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "10px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "10px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      order: "2",
      paddingTop: "0",
    },
    "& img": {
      marginRight: "0px",
      width: "20px",
      [theme.breakpoints.down(1028)]: {
        width: "15px",
        marginRight: "0",
      },
    },
    "& .MuiTypography-h6": {
      fontWeight: "500",
      fontSize: "22px",
      lineHeight: "29px",
      textTransform: "capitalize",
      color: "#FFFFFF",
      margin: "0",

      [theme.breakpoints.down(1028)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(1200)]: {
        fontSize: "16px",
      },
    },
    "& a": {
      textDecoration: "none",
    },
  },
  circulerLoader: {
    margin: "auto",
    "& .MuiCircularProgress-root": {
      color: "#454496",
    },
  },
  moreGamesLoader: {
    // marginBottom: "-20px",
    marginBottom: "40px",

    marginTop: "60px",
  },
  searchInput: {
    width: "100%",
  },
  providerDropDown: {
    marginLeft: "8px",
  },

  imageHover: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: theme?.palette?.background?.hoverBg,
    borderTop: theme?.palette?.background?.gameTopBorder,
    top: "0",
    left: "0",
  },
  "& playBtn": {
    width: "50px",
    objectFit: "contain",
    margin: "0 auto",
  },

  active: {
    background: `${theme.palette?.background?.toggleBackground} !important`,
    border: theme.palette?.background?.toggleBorder,
    "& span": {
      color: "#fff !important",
    },
    "& img": {
      mixBlendMode: "screen",
      filter:
        "grayscale(0) brightness(2) sepia(1) hue-rotate(200deg) saturate(5);",
    },
  },
  loadMoreButton: {
    boxShadow: "0px 4px 12px 2px rgba(108, 93, 193, 0.24)",
    maxWidth: "140px",
    width: "100%",
    height: "40px",
    margin: "0 auto !important",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "fit-content",
      minWidth: "92px",
      height: "32px",
      fontWeight: "600",
      fontSize: "14px",
      padding: "6px 16px !important",
      borderRadius: "8px !important",
    },
  },
  gameList: {
    overflowX: "scroll",
    marginTop: "120px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: "80px",
      overflow: "inherit",
    },
    "& .react-horizontal-scrolling-menu--item": {
      marginRight: "15px",
    },
    "& .react-horizontal-scrolling-menu--arrow-left": {
      top: "0",
      right: "40px",
      position: "absolute",
      transform: "translateY(0)",
    },

    "& .react-horizontal-scrolling-menu--arrow-right": {
      top: "0",
      right: "0px",
      position: "absolute",
      transform: "translateY(0)",
    },
    "& ul": {
      "& li": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: theme?.palette?.background?.tablebackground,
        boxShadow: "inset 0px 2px 4px rgba(255, 255, 255, 0.02)",
        borderRadius: "12px",
        padding: "8px 0",
        minHeight: "60px",
        maxHeight: "60px",
        minWidth: "116.8px",
        cursor: "pointer",
        "& span": {
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "20px",
          textTransform: "capitalize",
          color: "#757786",
          display: "inline-block",
          paddingTop: "4px !important",
          width: "100%",
          textAlign: "center",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          padding: "0 10px",
        },
        "&:hover": {
          opacity: "0.8",
        },
      },
    },
  },

  gamesWrapper: {
    justifyContent: "center",
    gap: "12px 18px",
    marginBottom: "60px",
    [theme.breakpoints.down("1439.98")]: {
      gap: "12px 13px",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "5px 5px",
      // justifyContent: "space-between",
      marginBottom: "0px",
      marginTop: "10px",
      // height: "calc(100vh - 690px)",
    },
    "& .MuiGrid-item": {
      float: "left",
      width: "155px",
      height: "228px",
      [theme.breakpoints.down("sm")]: {
        width: "32.15%",
        height: "200px",
        aspectRatio: "2",
      },
      [theme.breakpoints.down("xs")]: {
        width: "32.15%",
        height: "160px",
        aspectRatio: "2",
      },
    },
    "& .casinoCard": {
      "& .MuiTypography-body1": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: "120px",
        },
      },
    },
  },

  "& .css-vj1n65-MuiGrid-root": {
    marginRight: "14px",
  },

  sortBtn: {
    "& p": {
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      fontWeight: " 600",
      fontSize: "18px",
      lineHeight: "19px",
      display: "flex",
      alignItems: "center",
      color: "#FFFFFF",
      margin: "0 0 10px 0",
    },

    "& button": {
      background: "#202026",
      boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "12px !important",
      width: "100%",
      padding: "12px 15px !important",
      justifyContent: "flex-start",
      gap: "10px",
      display: "flex",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "17px",
      alignItems: "center",
      color: "#4D4D5B",
      textTransform: "capitalize",
      marginBottom: "30px",
      minHeight: "46px",
    },
  },

  resetBtn: {
    display: "none",
    background: theme?.palette?.background?.btntextBackground,
    border: "none",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "12px !important",
    minWidth: "120px",
    height: "44px",
    color: theme.palette?.text?.default,
    textTransform: "capitalize",
    fontSize: "18px",
    "&:hover": {
      background: theme?.palette?.background?.btntextBackground,
      border: theme.palette?.background?.inputBorder,
      color: theme.palette?.text?.default,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  selectTab: {
    background: theme?.palette?.background?.btntextBackground,
    border: theme.palette?.background?.inputBorder,
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "12px",
    minWidth: "120px",
    height: "44px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "120px",
      height: "38px",
      borderRadius: "8px",
      border: "1.4px solid rgba(255, 255, 255, 0.04)",
    },
    "& .css-9425fu-MuiOutlinedInput-notchedOutline": {
      border: "0",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "0px 0 0 10px !important",
      color: theme.palette?.text?.default,
      minHeight: "auto",
      fontSize: "14px",
    },
    "& svg": {
      color: "#7C8497 !important",
    },
    "& .MuiSelect-select MuiSelect-outlined": {
      WebkitTextFillColor: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },

  filterSection: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    [theme.breakpoints.down("md")]: {
      gap: "7px",
    },
  },

  filterText: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    whiteSpace: "nowrap",

    "& span": {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "normal",
      [theme.breakpoints.down("1200")]: {
        display: "none",
      },
    },
  },

  sortText: {
    marginRight: "58px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down(1500)]: {
      marginRight: "20px",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "0",
    },
    "& span": {
      [theme.breakpoints.down("1200")]: {
        display: "none",
      },
    },
  },

  ShowMoreBar: {
    "& p": {
      textAlign: "center",
      justifyContent: "center",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      color: theme.palette?.text?.color,
      margin: "0",
      padding: "12px 0 20px 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        padding: "10px 0 15px 0",
      },
    },
    "& .MuiLinearProgress-bar1Determinate": {
      background:
        "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
      boxShadow: "0px 2px 12px 2px rgba(108, 93, 193, 0.24)",
      borderRadius: "12px",
      transform: "none!important",
      width: (props) => `${props.gameProgressWidth}%`,
    },
  },
  more: {
    position: "relative",
    lineHeight: "0",
    marginTop: "60px",
    minWidth: "320px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "250px",
      marginTop: "40px",
    },
    "& .MuiLinearProgress-root": {
      backgroundColor: "#4D4D5B",
      boxShadow: "0px 4px 12px 2px rgba(108, 93, 193, 0.12)",
      borderRadius: "7px",
    },
    "& .MuiLinearProgress-bar1Determinate": {
      background:
        "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
      boxShadow: "0px 2px 12px 2px rgba(108, 93, 193, 0.24)",
      borderRadius: "12px",
    },
  },

  barBg: {
    background: theme.palette?.background?.barBg,
    borderRadius: "7px",
    display: "inline-block",
    width: "100%",
    height: "3px",
  },

  barBgProcess: {
    background: theme.palette?.background?.barBgProcessBg,
    boxShadow: theme.palette?.background?.barBgProcessShadow,
    borderRadius: "12px",
    display: "inline-block",
    width: "50%",
    height: "3px",
    position: "absolute",
    left: "0",
    top: "2px",
  },

  loadMoreBar: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "42px",
    width: "320px",
    marginTop: "60px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0px auto",
      marginBottom: "20px",
      marginTop: "0px !important",
      maxWidth: "300px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
  },
  linearBar: {
    background: "#4D4D5B !important",
    borderRadius: "4px",
    minHeight: "3px",
    overflow: "visible",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
      margin: "0 auto",
    },
  },
  signupCountryList: {
    "& .MuiMenu-paper": {
      maxHeight: "350px",
      backgroundColor: "transparent",
      boxShadow: "inherit",
      left: "10px !important",
      zIndex: 9999,
    },
    "& .MuiMenu-list": {
      background: theme.palette?.background?.filterbg,
      "& li": {
        color: theme.palette?.text?.default,
        minHeight: "32px",
      },
    },
  },
  selectGameTab: {
    minWidth: "100%",
    height: "48px",
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    borderRadius: "12px",
    background: "#2B2B38",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    fontFamily: "Jost!important",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "1.4375em",
    color: "#fff",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.04)!important",
    },
  },
  noGamesMsg: {
    [theme.breakpoints.down("sm")]: {
      color: "#4D4D5B",
      margin: "0",
      padding: "10px 10px 0px",
      fontSize: "22px",
      width: "100%",
      textAlign: "center",
    },
  },
}));
