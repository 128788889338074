import React from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";

import { StepsEnum } from "./constants";
import ErrorMessage from "../../../../utils/errorMessage";
import paymentService from "../../../../services/payment";

const mailValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function AchDirect({
  setStep,
  withdrawAmount,
  setTransactionStatus,
}) {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(mailValidationSchema),
  });

  const onSubmit = async ({ email }) => {
    const response = await paymentService.achDirectWithdraw({
      email,
      amount: withdrawAmount,
    });
    if (response.status === 200) {
      setStep(StepsEnum.Success);
      const { data } = response;

      setTransactionStatus({
        id: data.transactionId,
        createdAt: data.createdAt,
        actionType: "withdraw",
        amount: data.amount,
        paymentMethod: "ACH",
        status: data.status,
      });
      return;
    }
    toast.error(response?.message || "Internal Server error", {
      id: "withdraw-ach-direct-payment-error",
    });
  };
  return (
    <div className="achDirectRedeemMainWrap firstHeadingWrap">
      <span className="archTxt">
        Where would you like your fund to send to?
      </span>

      <div className="inputMainWrap paymentMainContentWrap">
        <div className="secondCopyInput">
          <span>Please input your email address</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="Your Email Address"
              {...register("email")}
            />
          </div>
          <ErrorMessage error={errors?.email} />
        </div>
      </div>
      <div className="flexBtnDiv bitCoinPaymentBtnWrap">
        <div className="select-payment-method-button" style={{ width: "100%" }}>
          <button onClick={() => setStep(StepsEnum.RedeemMethod)}>Back</button>
        </div>
        <div style={{ width: "100%" }}>
          <div className="buyNowBtnWrap">
            <button
              style={{ fontWeight: "500" }}
              onClick={handleSubmit(onSubmit)}
            >
              Redeem
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
