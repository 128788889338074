import React, { useState } from "react";

import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Step1 from "./step1";
import Step2 from "./step2";
import { step1Schema, step2Schema } from "../../../utils/validationSchema";

import "./kyc.css";

function KyacVarificationNew() {
  const [step, setStep] = useState(1);
  const schema = step === 1 ? step1Schema : step2Schema;

  const defaultValues =
    step === 1
      ? { firstname: "", lastname: "", dateofbirth: "", phoneNumber: "" }
      : {
          streetNumber: "",
          streetAddress1: "",
          streetAddress2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues,
      resolver: yupResolver(schema) ,
  });

  const nextStep = () => {
    setStep((prevStep) => Math.min(prevStep + 1, 2));
  };

  const prevStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  return (
    <div className="kycVarificationMainWrap" >
      <div className="sticyDiv">
        <div className="kycHeaderWrap loginHeadeWrap ">
          <p>Verification</p>
          <Box className="closeIcon">
            <img src="/images/loginCross.png" alt="" />
          </Box>
        </div>
        <div className="loginLogoWrap">
          <img src="/images/userIconLogo.png" className="kycLogoImg" alt="" />         
              {step === 1 ? (
                <Step1
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  handleSubmit={handleSubmit}
                  nextStep={nextStep}
                />
              ):step === 2 && (
                <Step2
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  handleSubmit={handleSubmit}
                  prevStep={prevStep}
                />)}
        </div>
      </div>
      </div>

   
  );
}

export default KyacVarificationNew;
