/* eslint-disable eqeqeq */
import React from "react";

import { Grid } from "@mui/material";

import useStyles from "../../container/Casino/Lobby/Lobby.styles";

const BetHistoryTable = ({ data, currentPage, pageLimit }) => {
  const classes = useStyles();

  return (
    <>
      {/* desk */}
      <Grid className={`${classes.tableResponsive} transaction-scroller  `}>
        <div className="mobileTableMainWrap">
          <div className="betTableMainWrap">
            <div className="transaction-head">
              <div className="transaction-head-column">
                <span>ID</span>
              </div>
              <div className="transaction-head-column">
                <span>Date/Time</span>
              </div>
              <div className="transaction-head-column">
                <span>Game</span>
              </div>
              <div className="transaction-head-column">
                <span>Win/Loss</span>
              </div>
              <div className="transaction-head-column">
                <span>GC/ST</span>
              </div>
              <div className="transaction-head-column">
                <span>Amount</span>
              </div>
              <div className="transaction-head-column">
                <span>Win/Loss</span>
              </div>
              <div className="transaction-head-column">
                <span>Before Balance</span>
              </div>
              <div className="transaction-head-column">
                <span>After Balance</span>
              </div>
            </div>

            {data && data?.length > 0 ? (
              data?.map((item, index) => {
                console.log("[item]", item);
                return (
                  <>
                    <div className="transaction-body">
                      <div className="transaction-body-row">
                        <div className="transaction-body-column">
                          <span>{currentPage * pageLimit + index + 1}</span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            {item?.updatedAt &&
                              `${new Date(item.updatedAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}`}
                          </span>
                          <span>{`${new Date(item.updatedAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            }
                          )}`}</span>
                        </div>
                        <div className="transaction-body-column">
                          <span className="betFlexDiv">
                            <img
                              src={item?.iconName}
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                            />
                            <span>{item?.name}</span>
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span
                            style={
                              item?.actionType === "win"
                                ? { color: "#6CD9A5" }
                                : { color: "#F25C5C" }
                            }
                          >
                            {item?.actionType}
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {item?.betCurrency === "USD" ? "GC" : "ST"}
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                              src={
                                item?.betCurrency === "HR"
                                  ? "/images/Bonus-coin-new-svg.svg"
                                  : "/images/Gold-coin1.png"
                              }
                              alt="bet-icon"
                            />
                            <span style={{ color: "#fff" }}>
                              {Math?.abs(
                                Number(
                                  item?.betAmount ||
                                    item?.bet_amount ||
                                    item?.amount ||
                                    0
                                )
                              )?.toFixed(2)}
                            </span>
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={
                                item?.betCurrency === "HR"
                                  ? "/images/Bonus-coin-new-svg.svg"
                                  : "/images/Gold-coin1.png"
                              }
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                            />
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span
                                style={{
                                  marginRight: "3px",
                                  color: "#fff",
                                  marginTop: "-3px",
                                }}
                              >
                                {item?.actionType === "win" ? "+" : "-"}
                              </span>
                              <span
                                style={{
                                  color: "#fff",
                                }}
                              >
                                {item?.actionType === "win"
                                  ? Math?.abs(
                                      Number(
                                        item?.providerId != 43
                                          ? item?.amount - item?.betAmount
                                          : item?.amount
                                      )
                                    )?.toFixed(2)
                                  : Math?.abs(
                                      Number(
                                        item?.betAmount ||
                                          item?.bet_amount ||
                                          item?.amount ||
                                          0
                                      )
                                    )?.toFixed(2)}
                              </span>
                            </span>
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={
                                item?.betCurrency === "HR"
                                  ? "/images/Bonus-coin-new-svg.svg"
                                  : "/images/Gold-coin1.png"
                              }
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                            />
                            <span style={{ color: "#fff" }}>
                              {(item?.rawBalance
                                ? Number(item.rawBalance)
                                : item?.actionType === "win" &&
                                  item?.providerId != 43
                                ? Number(item.beforeBalance || 0) +
                                  Number(item.betAmount)
                                : Number(item.beforeBalance || 0)
                              ).toFixed(2)}
                            </span>
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={
                                item?.betCurrency === "HR"
                                  ? "/images/Bonus-coin-new-svg.svg"
                                  : "/images/Gold-coin1.png"
                              }
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                            />
                            <span style={{ color: "#fff" }}>
                              {(item?.rawBalance
                                ? item?.actionType === "win"
                                  ? Number(item.rawBalance) +
                                    Number(item.amount)
                                  : Number(item?.rawBalance) -
                                    Number(item.betAmount)
                                : Number(item.afterBalance || 0)
                              ).toFixed(2)}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div
                style={{
                  textAlign: "center",
                  color: "#757786",
                  fontSize: "14px",
                  marginBottom: "10px",
                }}
              >
                <span style={{ textAlign: "center" }}>No History Found</span>
              </div>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

export default BetHistoryTable;
